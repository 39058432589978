import React from "react";
import theme from "theme";
import { Theme, Link, Image, Section, Text, Box, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Dataleaks.us
			</title>
			<meta name={"description"} content={"Dataleaks.us is a non-profit organization that started in 2021, We aim to notify people whose data have been leaked in the latest data breaches and provide resources to protect their privacy online"} />
			<meta property={"og:title"} content={"Dataleaks.us"} />
			<meta property={"og:description"} content={"Dataleaks.us is a non-profit organization that started in 2021, We aim to notify people whose data have been leaked in the latest data breaches and provide resources to protect their privacy online"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://i.imgur.com/eGfhJiA.png"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Link href="/" position="relative" transition="opacity 200ms ease" quarkly-title="Link">
				<Image
					src="https://uploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00:59:29.119Z"
					width="120px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64d09596098f07001999d2f7/images/cuted_logo.png?v=2023-08-19T00%3A59%3A29.119Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end" font="26px sans-serif">
				<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
				<Override
					slot="menu"
					md-top={0}
					md-width="100%"
					justify-content="center"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-left={0}
					lg-transform="translateY(0px) translateX(0px)"
					md-height="100%"
					padding="0px 0 0px 0"
					align-items="center"
				>
					<Override
						slot="item"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
						text-transform="uppercase"
						text-align="center"
						padding="8px 20px 8px 20px"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						md-hover-opacity="1"
						md-active-opacity="1"
						md-color="--dark"
						font="--base"
						text-transform="initial"
						md-font="16px/24px sans-serif"
						text-decoration-line="initial"
						color="--dark"
						transition="opacity .15s ease 0s"
						letter-spacing="0.5px"
						md-opacity=".5"
						md-transition="opacity .15s ease 0s"
						opacity=".5"
						hover-opacity="1"
					/>
					<Override
						slot="link-active"
						md-opacity="1"
						md-cursor="default"
						opacity="1"
						color="--primary"
						cursor="default"
					/>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
				<Override
					slot="icon"
					category="md"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
				/>
				<Override
					slot="menu-open"
					md-justify-content="center"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
				/>
			</Components.BurgerMenu>
			<Link
				href="/contact"
				md-display="none"
				white-space="nowrap"
				color="--light"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				font="--base"
				letter-spacing="0.5px"
				z-index="5"
				background="--color-primary"
				padding="8px 18px 8px 18px"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				text-decoration-line="initial"
			>
				Contact Us
			</Link>
		</Section>
		<Section
			lg-padding="25px 0 25px 0"
			sm-padding="0px 0 25px 0"
			justify-content="center"
			padding="25px 0 75px 0"
			sm-align-items="center"
			sm-justify-content="center"
			quarkly-title="Hero"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				width="100%"
				background="linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,#191C23 url(https://images.unsplash.com/photo-1493584753368-64a38a466b66?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center center/cover repeat scroll padding-box"
				height="620px"
				justify-content="center"
				sm-width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				align-items="center"
				lg-height="520px"
				md-height="420px"
				md-padding="0px 24px 0px 24px"
				color="#272525"
			/>
			<Text
				font="--headline1"
				margin="16px 0px 0px 0px"
				sm-text-align="center"
				sm-width="80%"
				lg-text-align="center"
				lg-font="--headline2"
				color="--light"
			>
				Dataleaks.us
			</Text>
			<Text
				sm-text-align="center"
				sm-width="80%"
				opacity="0.7"
				md-text-align="center"
				font="--lead"
				color="--light"
				margin="10px 0px 35px 0px"
			>
				Alerting You Today, for a Safer Tomorrow.
			</Text>
			<Link
				text-decoration-line="initial"
				color="--darkL2"
				padding="12px 24px 12px 24px"
				letter-spacing="0.5px"
				transition="transform --transitionDuration-normal --transitionTimingFunction-easeInOut 0.1s"
				href="https://article.dataleaks.us/"
				background="--color-light"
				font="--base"
				hover-transform="translateY(-4px)"
			>
				Learn More
			</Link>
		</Section>
		<Section
			box-sizing="border-box"
			lg-padding="50px 30px 50px 30px"
			id="mission"
			padding="90px 0px 100px 0px"
			quarkly-title="About"
			border-color="--color-lightD2"
			border-style="solid"
			border-width="1px 0px 1px 0px"
			sm-padding="24px 0 24px 0"
			background="--color-lightD1"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				align-items="center"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				transition="all --transitionDuration-normal ease-in 0s"
			/>
			<Text
				text-align="center"
				font="--base"
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				text-transform="uppercase"
				color="--dark"
				lg-text-align="center"
			>
				Our Mission
			</Text>
			<Text
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				font="normal 500 28px/1.2 'AvenirNextCyrRegular', sans-serif"
				margin="0px 0px 0px 0px"
			>
				We keep an eye on data breaches, notify those at risk, and aim for a safer online world for everyone
			</Text>
		</Section>
		<Section
			md-padding="25px 0px 25px 0px"
			justify-content="center"
			padding="70px 0 70px 0"
			quarkly-title="Info"
			lg-padding="50px 0px 50px 0px"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				max-width="1100px"
			/>
			<Box
				lg-grid-template-columns="1fr"
				display="grid"
				grid-template-columns="2fr 3fr"
				grid-gap="64px"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						font="--base"
						letter-spacing="1px"
						quarkly-title="Title"
						margin="0px 0px 10px 0px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
					>
						Team
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-font="--headline3"
					>
						Who We Are
					</Text>
					<Text
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="left"
					>
						dataleaks.us is a non-profit organization that watches data breach events around the world, Helping people know and protect their properties.
					</Text>
					<Link
						href="/about"
						color="--light"
						padding="8px 18px 8px 18px"
						text-align="center"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						text-decoration-line="initial"
						font="--base"
						letter-spacing="0.5px"
						margin="9px 0px 0px 0px"
						background="--color-primary"
						hover-transform="translateY(-4px)"
					>
						Read More
					</Link>
				</Box>
				<Box
					padding="0px 0px 60% 0px"
					height="0px"
					md-order="-1"
					width="100%"
					background="--color-lightD2 url(https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center center/100% no-repeat"
					margin="0px 0px 0px 0px"
					lg-order="-1"
				/>
			</Box>
			<Box
				md-margin="44px 0px 0px 0px"
				lg-grid-template-columns="1fr"
				display="grid"
				grid-template-columns="2fr 3fr"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
				lg-margin="64px 0px 0px 0px"
				grid-gap="64px"
				margin="96px 0px 0px 0px"
				justify-items="center"
				width="100px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					align-content="flex-start"
					order="0"
					flex-wrap="no-wrap"
					position="relative"
					md-width="max-content"
					md-height="max-content"
					lg-width="max-content"
					sm-width="400px"
					sm-height="auto"
					width="1100px"
				>
					<Text
						quarkly-title="Title"
						text-transform="uppercase"
						lg-text-align="center"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
						opacity="0.6"
						lg-margin="0px 0px 6px 0px"
						font="--base"
					>
						Dataleaks.us
					</Text>
					<Text
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-text-align="left"
						sm-font="--headline3"
						md-font="--headline3"
					>
						How does our system work?
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL1"
						opacity="0.6"
						lg-text-align="left"
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						text-align="left"
						width="800px"
						letter-spacing="initial"
						transition="all --transitionDuration-fast ease-in 0s"
						lg-width="100% border-box"
						lg-height="min-content"
						sm-width="350px"
						md-width="540px"
					>
						Our system monitors the digital space for data breaches. Upon detecting a breach, it evaluates the severity of exposed personal data by considering factors like registration date, exposure duration, and specific user credentials. This allows us to assess the risk level of potential financial loss or hacker attacks for each user, and send emails to those at higher risks.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			sm-padding="24px 0 24px 0"
			background="--color-lightD1"
			border-style="solid"
			box-sizing="border-box"
			justify-content="center"
			padding="90px 0px 100px 0px"
			border-color="--color-lightD2"
			border-width="1px 0px 1px 0px"
			lg-padding="50px 30px 50px 30px"
			quarkly-title="FAQ"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 48px 0px 48px"
				md-margin="0px 16px 0px 16px"
				align-items="center"
			/>
			<Text
				font="--base"
				margin="0px 0px 10px 0px"
				text-transform="uppercase"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				letter-spacing="1px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				lg-text-align="center"
			>
				Frequently Asked Questions
			</Text>
			<Text
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 64px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
				lg-margin="0px 0px 36px 0px"
			>
				FAQ
			</Text>
			<Box
				width="100%"
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box>
					<Text
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
					>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							What is a data breach?
						</Span>
					</Text>
					<Text
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
					>
						A data breach occurs when unauthorized individuals gain access to confidential information, often held by companies or institutions. This can include personal data such as names, email addresses, social security numbers, and financial details.
						<br />
					</Text>
				</Box>
				<Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
					>
						How do data breaches happen?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						Data breaches can occur due to various reasons, including weak passwords, malware or phishing attacks, unsecured networks, insider threats, or outdated security systems.
						<br />
					</Text>
				</Box>
				<Box>
					<Text
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
					>
						What information is typically exposed in a data breach?
					</Text>
					<Text
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
					>
						The type of information exposed varies with each breach. Commonly compromised data includes passwords, credit card numbers, health records, social security numbers, personal addresses, and other sensitive details.
					</Text>
				</Box>
				<Box>
					<Text
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
					>
						How can I find out if my data was part of a breach?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						You can use tools like the one on dataleaks.us to check if your account has been compromised in recent breaches. Additionally, companies usually inform affected customers of a breach either through email or official announcements.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 40px 0" quarkly-title="Footer-12">
			<Box
				display="grid"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				lg-padding="0 0px 50px 0px"
				margin="0px 0px 50px 0px"
				md-flex-wrap="wrap"
				md-padding="0 0px 0 0px"
				md-margin="0px 0px 40px 0px"
				grid-template-columns="repeat(3, 1fr)"
				lg-align-items="start"
				grid-gap="36px 34px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					align-items="flex-start"
					lg-align-items="flex-start"
					justify-content="flex-start"
					display="grid"
					lg-flex-direction="column"
					flex-direction="column"
					flex-wrap="wrap"
					align-content="start"
					grid-gap="10px 0"
					lg-justify-content="start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans" md-margin="0px 0px 10px 0px">
						Menu
					</Text>
					<Link
						border-color="--color-primary"
						display="flex"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						margin="0px 0 0px 0"
						lg-border-width="0px 0px 0px 2px"
						href="/index"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
						target="_self"
					>
						Home
					</Link>
					<Link
						margin="0px 0 0px 0"
						display="flex"
						href="/about"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						hover-color="#6d32ec"
						target="_self"
						user-select="auto"
						pointer-events="auto"
					>
						About
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="/team"
						text-decoration-line="initial"
						color="--darkL1"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						display="flex"
						target="_top"
					>
						Password-check
					</Link>
					<Link
						margin="0px 0 0px 0"
						hover-color="#6d32ec"
						href="https://article.dataleaks.us/"
						font="normal 400 16px/24px --fontFamily-googleRoboto"
						text-decoration-line="initial"
						color="--darkL1"
						display="flex"
						target="_top"
					>
						Articles
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Contact
					</Text>
					<Link
						color="#5a5d64"
						text-decoration-line="initial"
						font="--base"
						display="inline-block"
						margin="0px 0px 15px 0px"
						hover-color="#6d32ec"
					>
						support@dataleaks.us
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-padding="0px 0px 0px 0"
					md-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.5 --fontFamily-sans">
						Dataleaks.us
					</Text>
					<Text margin="0px 0px 40px 0px" font="--base" color="#5a5d64">
						dataleaks.us is a non-profit organization that watches data breach events around the world, Helping people know and protect their properties.
					</Text>
				</Box>
			</Box>
			<Box
				display="block"
				padding="0 0px 0px 0px"
				border-color="--color-lightD2"
				md-padding="0 0px 0px 0px"
				md-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="#5a5d64"
					md-margin="0px 0px 25px 0px"
					sm-text-align="center"
					text-align="center"
					letter-spacing="1px"
				>
					© 2021-2023 Dataleaks.us  . All rights reserved.
				</Text>
			</Box>
		</Section>
	
	</Theme>;
});